import { lazy } from "react";

const Home = lazy(() => import("./home/Home"));
const Login = lazy(() => import("./login/Login"));
const ForgotPassword = lazy(() => import("./forgot-password"));
const UserList = lazy(() => import("./users/UserList"));
const AddUser = lazy(() => import("./users/AddUser"));
const ChangePassword = lazy(() => import("./change-password/ChangePassword"));
const Prior = lazy(() => import("./prior/Prior"));
//const TrackOrder = lazy(() => import("./track-order/TrackOrder"));

// Profile Pages
const Profile = lazy(() => import("./profile/Profile"));
const EmailNotificationPermissions = lazy(
  () => import("./profile/EmailNotificationPermissions")
);

// Customer Management
const Customers = lazy(() => import("./customer-management/Customers/index"));
//const CustomerMarkupList = lazy(() => import("./customer-management/CustomerMarkup/index"));
//const AddEditCustomerMarkup = lazy(() => import("./customer-management/CustomerMarkup/CustomerMarkup"));
const AddCustomer = lazy(
  () => import("./customer-management/Customers/AddCustomer")
);
const CustomerUsers = lazy(() => import("./customer-management/CustomerUsers"));
const AddEditCustomerUsers = lazy(
  () => import("./customer-management/CustomerUsers/AddEditCustomerUsers")
);
const AddCustomerOfCustomer = lazy(
  () => import("./customer-management/Customers/Customers")
);
//const CustomerRate = lazy(() => import("./customer-management/CustomerRates"));
const CustomerRate = lazy(
  () => import("./customer-management/CustomerRateTabs")
);
const AddEditCustomerAdditionalCharges = lazy(
  () =>
    import(
      "./customer-management/CustomerRateTabs/CustomerAdditionalCharges/AddEditCustomerAdditionalCharges"
    )
);
const AddEditCustomerInvoiceGroups = lazy(
  () => import("./customer-management/CustomerRateTabs/CustomerInvoiceGroup/AddEditCustomerInvoiceGroups")
);

// Agent Management
const AgentList = lazy(() => import("./agent-management/AgentList/index"));
const AddEditAgentList = lazy(
  () => import("./agent-management/AgentList/AddEditAgentList")
);
const AgentListTabs = lazy(
  () => import("./agent-management/AgentList/AgentListTabs")
);
const MasterAgentList = lazy(
  () => import("./agent-management/MasterAgentList")
);
const MasterAgentListTabs = lazy(
  () => import("./agent-management/MasterAgentList/MasterAgentListTabs")
);
const AgentHubs = lazy(() => import("./agent-management/AgentHubs/index"));
const AddEditAgentHub = lazy(
  () => import("./agent-management/AgentHubs/addEditAgentHub/index")
);
const AgentDocList = lazy(
  () => import("./agent-management/AgentDocList/index")
);
const AddEditAgentAdditionalCharges = lazy(
  () =>
    import(
      "./agent-management/AgentList/AgentListTabs/AgentAdditionalCharges/AddEditAgentAdditionalCharges"
    )
);

// Order Mangement
const CustomerOrder = lazy(
  () => import("./Order-management/CustomerOrder/index")
);
const CustomerOrderHistory = lazy(
  () => import("./Order-management/CustomerOrderHistory/index")
);
const CustomerOrderHistoryEdit = lazy(
  () =>
    import("./Order-management/CustomerOrderHistory/CustomerOrderHistoryEdit")
);
const DispatchBoardList = lazy(
  () => import("./Order-management/DispatchBoard/index")
);
const BilledOrderHistory = lazy(
  () => import("./Order-management/BilledOrderHistory/index")
);
const ViewBilledOrderHistory = lazy(
  () => import("./Order-management/BilledOrderHistory/ViewBilledOrderHistory")
);

// Api Management
const XcelaratorApi = lazy(
  () => import("./api-management/XcelaratorApi/index")
);
const AddEditXcelaratorApi = lazy(
  () => import("./api-management/XcelaratorApi/AddEditXcelaratorApi")
);
const AddEditXcelaratorApiTabs = lazy(
  () => import("./api-management/XcelaratorTabs/index")
);
// Account managenet
const InvoiceGroups = lazy(
  () => import("./account-management/GroupInvoice/index")
);
const AddEditInvoiceGroups = lazy(
  () => import("./account-management/GroupInvoice/AddEditInvoiceGroups")
);
const InvoiceManagement = lazy(
  () => import("./account-management/Invoice/Index")
);
const AddEditOrderInvoice = lazy(
  () => import("./account-management/Invoice/AddEditOrderInvoice")
);
const FinalizedOrders = lazy(
  () => import("./account-management/Accounting/Index")
);
//Report
const CreateReport = lazy(() => import("./report/CreateReport/index"));
const SavedReports = lazy(()=> import("./report/SavedReports/index"));

// Settings
const SettingsAdditionalCharges = lazy(
  () => import("./settings/AdditionalCharges")
);
const AddEditSettingsAdditionalCharges = lazy(
  () => import("./settings/AdditionalCharges/AddEditSettingsAdditionalCharges")
);

export {
  //CustomerMarkupList,
  //AddEditCustomerMarkup,
  Home,
  Login,
  ForgotPassword,
  Profile,
  AddUser,
  ChangePassword,
  UserList,
  Prior,
  Customers,
  AddCustomer,
  CustomerUsers,
  AddEditCustomerUsers,
  AgentList,
  AddEditAgentList,
  AgentListTabs,
  MasterAgentList,
  MasterAgentListTabs,
  AgentHubs,
  AddEditAgentHub,
  AgentDocList,
  CustomerOrder,
  CustomerOrderHistory,
  CustomerOrderHistoryEdit,
  DispatchBoardList,
  BilledOrderHistory,
  ViewBilledOrderHistory,
  XcelaratorApi,
  AddEditXcelaratorApi,
  AddEditXcelaratorApiTabs,
  AddCustomerOfCustomer,
  CustomerRate,
  EmailNotificationPermissions,
  InvoiceGroups,
  AddEditInvoiceGroups,
  InvoiceManagement,
  AddEditOrderInvoice,
  CreateReport,
  SavedReports,
  SettingsAdditionalCharges,
  AddEditSettingsAdditionalCharges,
  AddEditAgentAdditionalCharges,
  AddEditCustomerAdditionalCharges,
  AddEditCustomerInvoiceGroups,
  FinalizedOrders,
  //TrackOrder
};
