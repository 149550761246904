import "./trackorder.css";

import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { postApi, getApi } from "../../apis/api";
import {
  LogoTracking,
  ShippingIcon,
  UserIcon,
  SettingIcon,
  TruckTrackOrder,
  NeedHelp,
  ArrowDownLightIcon,
  ArrowUpLightIcon,
} from "../../assets";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import moment from "moment";

import { Loader } from "../../components/loaders/loaders";
import { 
  BOOKING_ORDER_STATUS, 
  IN_TRANSIT_ORDER_STATUS, 
  OUT_DELIVERY_ORDER_STATUS, 
  DELIVERED_ORDER_STATUS, 
  CANCELLED_ORDER_STATUS 
} from "../../utils/constants";

interface OrderDetailsType {
  orderNumber: string;
  createdAt: string;
  orderStatus: any[];
  orderItems: any[];
  orderType: {
    name: string;
  };
  customer: {
    logo: string;
  }
}

interface BarStatusDetails {
  id: string;
  bookedDate: string;
  inTransitDate: string;
  outForDeliveryDate: string;
  deliveryDate: string;
  cancelDate: string;
}

interface OrderStatusTableTypes {
  orderStatusType: {
    name: string;
    description: string;
  };
  statusNote: string;
  statusDate: string;
  statusTime: string;
  statusLocation: string;
  zipCode: string;
  createdAt: string;
  agent: any;
  addedBy: string;
  eventDateTime?: string;
}

const TrackOrder = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [customerLogo, setCustomerLogo] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<OrderDetailsType>({
    orderNumber: "",
    createdAt: "",
    orderStatus: [],
    orderItems: [],
    orderType: {
      name: "",
    },
    customer: {
      logo: ""
    }
  });
  const [barOrderStatus, setBarOrderStatus] = useState<BarStatusDetails>({
    id: "",
    bookedDate: "",
    inTransitDate: "",
    outForDeliveryDate: "",
    deliveryDate: "",
    cancelDate: "",
  });
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    getOrderTrackingDetails();
  }, []);

  const getLTLOrderStatus = (LTLOrderData: any) => {
    if (
      LTLOrderData?.fulfillmentStatusList &&
      LTLOrderData?.fulfillmentStatusList.length > 0
    ) {
      const userData = LTLOrderData?.fulfillmentStatusList.map((item: any) => ({
        ...item,
        ...formatTimeWithOffset(item?.eventDateTime, item?.timeZoneOffset),
      }));

      userData.sort(
        (a: OrderStatusTableTypes, b: OrderStatusTableTypes) =>
          Number(new Date(b?.eventDateTime || "") || 0) -
          Number(new Date(a?.eventDateTime || "") || 0)
      );
      return userData.map((item: any, index: number) => {
        return {
          id: item.id,
          orderStatus:
            (item?.type ? "" : "LTL - ") +
            item?.code
              .split("_") // Split the string by underscores
              .map(
                (
                  word: string // Map over each word
                ) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter and make the rest lowercase
              )
              .join(" "),
          statusDate: item?.statusDate
            ? moment.utc(item?.statusDate).local().format("MM-DD-YYYY")
            : "",
          statusTime: item?.statusDate
            ? moment.utc(item?.statusDate).local().format("hh:mm:ss A")
            : "",
          statusLocation: item?.statusLocation || "",
          zipCode: item?.zipCode || "",
        };
      });
    } else {
      return [];
    }
  };

  const getBarStatus = (orderData: any) => {
    let barStatusDetails: BarStatusDetails = {
      id: "",
      bookedDate: "",
      inTransitDate: "",
      outForDeliveryDate: "",
      deliveryDate: "",
      cancelDate: "",
    };
    if (orderData.length) {
      let sortedOrderStatus = [];
      if (orderData?.[0]?.apiVersion) {
        let newOrderStatus = orderData?.[0]?.fulfillmentStatusList.filter(
          (item: any) => item.hasOwnProperty("id")
        );
        sortedOrderStatus = newOrderStatus?.sort(
          (a: { statusDate: string }, b: { statusDate: string }) =>
            moment(a.statusDate) > moment(b.statusDate)
              ? -1
              : moment(a.statusDate) < moment(b.statusDate)
              ? 1
              : 0
        );
      } else {
        sortedOrderStatus = orderData?.sort(
          (a: { statusDate: string }, b: { statusDate: string }) =>
            moment(a.statusDate) > moment(b.statusDate)
              ? -1
              : moment(a.statusDate) < moment(b.statusDate)
              ? 1
              : 0
        );
      }

      const getBookedDate = (data: any) => {
        let bookedData = data.find(
          (obj1: any) => BOOKING_ORDER_STATUS.some(obj2 => obj1.orderStatusType.name === obj2)
        );
        return bookedData?.statusDate;
      };
      const getInTransitDate = (data: any) => {
        let bookedData = data.find(
          (obj1: any) => IN_TRANSIT_ORDER_STATUS.some(obj2 => obj1.orderStatusType.name === obj2)
        );
        return bookedData?.statusDate;
      };
      const getOutForDeliveryDate = (data: any) => {
        let bookedData = data.find(
          (obj1: any) => OUT_DELIVERY_ORDER_STATUS.some(obj2 => obj1.orderStatusType.name === obj2)
        );
        return bookedData?.statusDate;
      };

      const latestStatus = sortedOrderStatus[0]["orderStatusType"];
      if (BOOKING_ORDER_STATUS.includes(latestStatus?.name)) {
        barStatusDetails["id"] = "booked";
        barStatusDetails["bookedDate"] = sortedOrderStatus[0]["statusDate"];
        barStatusDetails["inTransitDate"] = ""; //getInTransitDate(sortedOrderStatus);
        barStatusDetails["outForDeliveryDate"] = ""; //getOutForDeliveryDate(sortedOrderStatus);
        barStatusDetails["deliveryDate"] =  ""; //getDeliveredDate(sortedOrderStatus);
      } else if (OUT_DELIVERY_ORDER_STATUS.includes(latestStatus?.name)) {
        barStatusDetails["id"] = "outForDelivery";
        barStatusDetails["bookedDate"] = getBookedDate(sortedOrderStatus);
        barStatusDetails["inTransitDate"] = getInTransitDate(sortedOrderStatus);
        barStatusDetails["outForDeliveryDate"] =
          sortedOrderStatus[0]["statusDate"];
        barStatusDetails["deliveryDate"] = ""; //getDeliveredDate(sortedOrderStatus);
      } else if (DELIVERED_ORDER_STATUS.includes(latestStatus?.name)) {
        barStatusDetails["id"] = "delivered";
        barStatusDetails["bookedDate"] = getBookedDate(sortedOrderStatus);
        barStatusDetails["inTransitDate"] = getInTransitDate(sortedOrderStatus);
        barStatusDetails["outForDeliveryDate"] =
          getOutForDeliveryDate(sortedOrderStatus);
        barStatusDetails["deliveryDate"] = sortedOrderStatus[0]["statusDate"];
      } 
      // else if (CANCELLED_ORDER_STATUS.includes(latestStatus?.name)) {
      //   barStatusDetails["id"] = "cancelled";
      //   barStatusDetails["bookedDate"] = getBookedDate(sortedOrderStatus);
      //   barStatusDetails["cancelDate"] = sortedOrderStatus[0]["statusDate"];
      // } 
      else if (IN_TRANSIT_ORDER_STATUS.includes(latestStatus?.name)){
        barStatusDetails["id"] = "inTransit";
        barStatusDetails["bookedDate"] = getBookedDate(sortedOrderStatus);
        barStatusDetails["inTransitDate"] = sortedOrderStatus[0]["statusDate"];
        barStatusDetails["outForDeliveryDate"] = ""; //getOutForDeliveryDate(sortedOrderStatus);
        barStatusDetails["deliveryDate"] = "";  //getDeliveredDate(sortedOrderStatus);
      }
      else {
        barStatusDetails["id"] = "";
        barStatusDetails["bookedDate"] = "";
        barStatusDetails["inTransitDate"] = "";
        barStatusDetails["outForDeliveryDate"] = "";
        barStatusDetails["deliveryDate"] = "";
      }
    }

    return barStatusDetails;
  };

  function formatTimeWithOffset(timeStr: string, offsetStr: string) {
    // Parse the time string based on its format
    let date;
    if (timeStr) {
      if (timeStr.includes("T")) {
        // For ISO format with 'T' and 'Z' (e.g., 2024-11-19T12:59:14.000Z)
        date = new Date(timeStr);
      } else {
        // For regular format without 'T' (e.g., 2024-11-19 07:59:09)
        date = new Date(timeStr.replace(" ", "T")); // Convert to ISO-like string
      }

      // Parse the timezone offset (e.g., '0500' for +5 hours)
      if (offsetStr) {
        let offsetHours = parseInt(offsetStr.substring(0, 2), 10);
        let offsetMinutes = parseInt(offsetStr.substring(2, 4), 10);
        let offsetMilliseconds = (offsetHours * 60 + offsetMinutes) * 60000; // Convert to milliseconds

        // Adjust the date by applying the offset
        date = new Date(date.getTime() + offsetMilliseconds);
      }

      // Convert the time to UTC
      let utcDate = new Date(date.toISOString()); // Ensure it's in UTC

      // Format the date to "dd:mm:yyyy hh:mm:ss aa"
      let day = String(utcDate.getUTCDate()).padStart(2, "0");
      let month = String(utcDate.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
      let year = utcDate.getUTCFullYear();

      let hours = utcDate.getUTCHours();
      let minutes = String(utcDate.getUTCMinutes()).padStart(2, "0");
      let seconds = String(utcDate.getUTCSeconds()).padStart(2, "0");

      // Determine AM/PM
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12; // Convert to 12-hour format
      hours = hours ? hours : 12; // The hour '0' should be '12'

      // Return the formatted date
      return {
        eventDate: `${month}-${day}-${year}`,
        eventTime: `${hours}:${minutes}:${seconds} ${ampm}`,
      };
    }
    return {
      eventDate: "",
      eventTime: "",
    };
  }

  const getOrderTrackingDetails = async () => {
    try {
      setLoading(true);
      const url = `order/trackingorderstatus`;
      const apiPayload = { id };
      const apiResponse = await postApi<any>(url, apiPayload);
      if (apiResponse.success) {
        const isLTL =
          apiResponse?.data?.orderType?.name === "LTL" ||
          apiResponse?.data?.orderType?.name === "LTL + Final Mile";
        setOrderDetails(apiResponse.data);
        const getCustomerLogo = apiResponse.data.customer.customerLevel === "third" ? apiResponse.data.customer?.parentCustomer?.parentCustomer?.logo : apiResponse.data.customer.customerLevel === "second" ? apiResponse.data.customer?.parentCustomer?.logo : apiResponse.data.customer?.logo;
        setCustomerLogo(getCustomerLogo);
        const mappedSortedData = (userData: any) => {
          const newData = userData.map((user: any) => {
            return {
              id: user.id,
              orderStatus: user.orderStatusType?.name,
              statusLocation: user.statusLocation,
              zipCode: user?.zipCode || "",
              statusDate: user?.statusDate
                ? moment.utc(user?.statusDate).local().format("MM-DD-YYYY")
                : "--",
              statusTime: user?.statusDate
                ? moment.utc(user?.statusDate).local().format("hh:mm:ss A")
                : "",
            };
          });
          return newData;
        };

        if (isLTL) {
          setStatusData(getLTLOrderStatus(apiResponse?.data?.orderStatus[0]));
        } else {
          setStatusData(mappedSortedData(apiResponse?.data?.orderStatus));
        }
        const barOrderStatus = getBarStatus(apiResponse?.data?.orderStatus);
        setBarOrderStatus(barOrderStatus);
      }
    } catch (error) {
      console.log(GENERAL_ERROR_MESSAGE, error);
    } finally {
      setLoading(false);
    }
  };

  const getBookedCircleClass = (status: string) => {
    let circleClassName = "stepsWrap__item";
    if (
      status === "booked" ||
      status === "inTransit" ||
      status === "outForDelivery" ||
      status === "delivered"
    ) {
      circleClassName = circleClassName + " stepsWrap__item--done";
    } 
    // else if (status === "cancelled") {
    //   circleClassName = circleClassName + " stepsWrap__item--cancel";
    // }
    return circleClassName;
  };

  const getBookedLineClass = (status: string) => {
    let circleClassName = "stepsWrap__item";
    if (
      status === "inTransit" ||
      status === "outForDelivery" ||
      status === "delivered"
    ) {
      circleClassName =
        circleClassName + " stepsWrap__item--active stepsWrap__item--done";
    }
    return circleClassName;
  };

  const getOutForDeliveryClass = (status: string) => {
    let circleClassName = "stepsWrap__item";
    if (status === "outForDelivery" || status === "delivered") {
      circleClassName =
        circleClassName + " stepsWrap__item--active stepsWrap__item--done";
    }
    return circleClassName;
  };

  const getDeliveredClass = (status: string) => {
    let circleClassName = "stepsWrap__item";
    if (status === "delivered") {
      circleClassName =
        circleClassName + " stepsWrap__item--active stepsWrap__item--done";
    }
    return circleClassName;
  };

  const getCancelClass = (status: string) => {
    let circleClassName = "stepsWrap__item";
    if (status === "cancelled") {
      circleClassName = circleClassName + " stepsWrap__item--cancel";
    }
    return circleClassName;
  };

  const sortedData = useMemo(() => {
    let sortableData = [...statusData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [statusData, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };

  return (
    <>
      <section className="siteBanner">
        <div className="siteHeader">
          <h1 className="heading">Order Details</h1>
          <div className="logo">
            {/* <LogoTracking /> */}
            { customerLogo ? <img src={customerLogo} /> : "" }
          </div>
        </div>
        <div className="siteBannerContent">
          <div className="topblock">
            <h2>Track Order With Ease</h2>
            <p>
              Now track your order with ease and get updated about your product
            </p>
          </div>
          <div className="commonList">
            <div className="item">
              <div className="card">
                <div className="icon">
                  <ShippingIcon />
                </div>
                <h3>Fast Shipping</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Purus mattis vel in
                  odio viverra cras dui.{" "}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="card">
                <div className="icon">
                  <UserIcon />
                </div>
                <h3>Authentic Delivery</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Purus mattis vel in
                  odio viverra cras dui.{" "}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="card">
                <div className="icon">
                  <SettingIcon />
                </div>
                <h3>Support System</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Purus mattis vel in
                  odio viverra cras dui.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="commonSec">
        <div className="commonCardHead">
          <div className="item">
            <h4>Order ID: {orderDetails?.orderNumber}</h4>
            <p>
              <span className="date">
                <span>Order Type:</span> {orderDetails?.orderType?.name}
              </span>
            </p>
            <p>
              <span className="date">
                <span>Order date:</span>{" "}
                {moment(orderDetails?.createdAt).format("MMM DD, YYYY")}
              </span>
              <span className="text">
                <TruckTrackOrder />
                Estimated delivery:
              </span>
            </p>
          </div>
          <div className="item">
            <div className="btnGroup">
              {/* <button className="btn">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.2918 16.6354H6.62516C4.03975 16.6354 2.55225 15.1479 2.55225 12.5625V5.47917C2.55225 2.89375 4.03975 1.40625 6.62516 1.40625H12.2918C14.8772 1.40625 16.3647 2.89375 16.3647 5.47917V12.5625C16.3647 15.1479 14.8772 16.6354 12.2918 16.6354ZM6.62516 2.46875C4.59933 2.46875 3.61475 3.45333 3.61475 5.47917V12.5625C3.61475 14.5883 4.59933 15.5729 6.62516 15.5729H12.2918C14.3177 15.5729 15.3022 14.5883 15.3022 12.5625V5.47917C15.3022 3.45333 14.3177 2.46875 12.2918 2.46875H6.62516Z"
                    fill="#667085"
                  />
                  <path
                    d="M14.0626 7.07552H12.6459C11.5692 7.07552 10.698 6.20427 10.698 5.1276V3.71094C10.698 3.42052 10.9388 3.17969 11.2292 3.17969C11.5197 3.17969 11.7605 3.42052 11.7605 3.71094V5.1276C11.7605 5.61635 12.1572 6.01302 12.6459 6.01302H14.0626C14.353 6.01302 14.5938 6.25385 14.5938 6.54427C14.5938 6.83469 14.353 7.07552 14.0626 7.07552Z"
                    fill="#667085"
                  />
                  <path
                    d="M9.45858 10.2656H6.62524C6.33483 10.2656 6.09399 10.0248 6.09399 9.73438C6.09399 9.44396 6.33483 9.20312 6.62524 9.20312H9.45858C9.74899 9.20312 9.98983 9.44396 9.98983 9.73438C9.98983 10.0248 9.74899 10.2656 9.45858 10.2656Z"
                    fill="#667085"
                  />
                  <path
                    d="M12.2919 13.0938H6.62524C6.33483 13.0938 6.09399 12.8529 6.09399 12.5625C6.09399 12.2721 6.33483 12.0312 6.62524 12.0312H12.2919C12.5823 12.0312 12.8232 12.2721 12.8232 12.5625C12.8232 12.8529 12.5823 13.0938 12.2919 13.0938Z"
                    fill="#667085"
                  />
                </svg>
                Invoice
              </button> */}
              <button className="btn btnBlue">
                Need Help
                <NeedHelp />
              </button>
            </div>
          </div>
        </div>
        <div className="commonCard">
          {barOrderStatus.id !== "cancelledTest" ? (
            <ul className="stepsWrap">
              <li
                className={getBookedCircleClass(barOrderStatus.id)}
                id="booked"
              >
                <div className="stepsWrap__item__content">
                  <span className="text">Booked</span>
                  <span className="circle"></span>
                  <span className="value">
                    {moment.utc(barOrderStatus.bookedDate).local().format("ddd, MMM Do")}
                  </span>
                </div>
              </li>
              <li
                className={getBookedLineClass(barOrderStatus.id)}
                id="inTransit"
              >
                <div className="stepsWrap__item__content">
                  <span className="text">In Transit</span>
                  <span className="circle"></span>
                  <span className="value">
                    {barOrderStatus.inTransitDate ? (
                      moment.utc(barOrderStatus.inTransitDate).local().format("ddd, MMM Do")
                    ) : (
                      <>&nbsp;</>
                    )}
                  </span>
                </div>
              </li>
              <li
                className={getOutForDeliveryClass(barOrderStatus.id)}
                id="outForDelivery"
              >
                <div className="stepsWrap__item__content">
                  <span className="text">Out for Delivery</span>
                  <span className="circle"></span>
                  <span className="value">
                    {barOrderStatus.outForDeliveryDate ? (
                      moment.utc(barOrderStatus.outForDeliveryDate).local().format(
                        "ddd, MMM Do"
                      )
                    ) : (
                      <>&nbsp;</>
                    )}
                  </span>
                </div>
              </li>
              <li
                className={getDeliveredClass(barOrderStatus.id)}
                id="delivered"
              >
                <div className="stepsWrap__item__content">
                  <span className="text">Delivered</span>
                  <span className="circle"></span>
                  <span className="value">
                    {barOrderStatus.deliveryDate ? (
                      `${moment.utc(
                        barOrderStatus.deliveryDate
                      ).local().format("ddd, MMM Do")}`
                    ) : (
                      <>&nbsp;</>
                    )}
                  </span>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="stepsWrap stepsWrapTwo">
              <li
                className={getBookedCircleClass(barOrderStatus.id)}
                id="booked"
              >
                <div className="stepsWrap__item__content">
                  <span className="text">Booked</span>
                  <span className="circle"></span>
                  <span className="value">
                    {moment.utc(barOrderStatus.bookedDate).local().format("ddd, MMM Do")}
                  </span>
                </div>
              </li>

              <li className={getCancelClass(barOrderStatus.id)} id="delivered">
                <div className="stepsWrap__item__content">
                  <span className="text">Cancelled</span>
                  <span className="circle"></span>
                  <span className="value">
                    {barOrderStatus.cancelDate ? (
                      moment.utc(barOrderStatus.cancelDate).local().format("ddd, MMM Do")
                    ) : (
                      <>&nbsp;</>
                    )}
                  </span>
                </div>
              </li>
            </ul>
          )}
        </div>
        <div className="commonCard">
          <h4>Order Details</h4>
          <div className="tableWrap">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div className="sortingDiv">S.</div>
                  </th>
                  <th>
                    <div className="sortingDiv">Handling Unit Type</div>
                  </th>
                  <th>
                    <div className="sortingDiv center">Handling Unit Amt</div>
                  </th>
                  <th>
                    <div className="sortingDiv center">Total Items/Pieces</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderDetails?.orderItems.map((item: any, index: number) => (
                  <tr>
                    <td>{`Item #${index + 1}`}</td>
                    <td>{item?.packageType}</td>
                    <td className="center">{item?.handlingUnit}</td>
                    <td className="center">{item?.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/*<div className="commonCard">
          <h4>Order Details</h4>
          <div className="commonCardRow">
            <div className="orderInfo">
              <div className="picture">
                <img src={ProductImage} alt="" />
              </div>
              <div className="content">
                <h6>MackBook Pro 14’’</h6>
                <p>Space Gray | 32GB | 1 TB</p>
              </div>
            </div>
            <h5>QTY: 2</h5>
          </div>
        </div>*/}
        <div className="commonCard">
          <h4>Order Tracking Details</h4>
          <div className="tableWrap">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div className="sortingDiv">S.</div>
                  </th>
                  <th>
                    <div
                      className="sortingDiv"
                      onClick={() => requestSort("orderStatus")}
                    >
                      Order Status
                      {getSortIcon("orderStatus")}
                    </div>
                  </th>
                  <th>
                    <div
                      className="sortingDiv center"
                      onClick={() => requestSort("location")}
                    >
                      Location
                      {/* {getSortIcon('location')} */}
                    </div>
                  </th>
                  <th>
                    <div className="sortingDiv center">Loc Zip</div>
                  </th>
                  <th>
                    <div
                      className="sortingDiv center"
                      onClick={() => requestSort("statusDate")}
                    >
                      Status Date
                      {getSortIcon("statusDate")}
                    </div>
                  </th>
                  <th>
                    <div className="sortingDiv center">Status Time</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item: any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.orderStatus}</td>
                    <td className="center">{item?.statusLocation}</td>
                    <td className="center">{item?.zipCode}</td>
                    <td className="center">
                      {item?.statusDate
                        ? moment(item?.statusDate).format("MM/DD/YYYY")
                        : ""}
                    </td>
                    <td className="center">
                      {item?.statusTime ? item?.statusTime : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {loading && <Loader />}
    </>
  );
};

export default TrackOrder;
