export const EMAIL = {
  label: "Email",
  validation: {
    required: "Please enter an email",
    valid: "Please enter a valid email address.",
  },
};

export const PHONENUMBER = {
  label: "PhoneNumber",
  validation: {
    required: "Phone number is required",
    valid: "Please enter valid phone number",
  },
};

export const FIRST_NAME = {
  label: "First Name",
  validation: {
    required: "First name is required.",
    valid: "Please enter valid first name",
  },
};

export const LAST_NAME = {
  label: "Last Name",
  validation: {
    required: "Last name is required.",
    valid: "Please enter valid last name",
  },
};

export const ROLE = {
  label: "ROLE",
  validation: {
    required: "Role is required.",
  },
};

export const PASSWORD = {
  label: "Password",
  validation: {
    required: "Please enter password.",
    valid: "Incorrect password.",
    match: "Password and Confirm Password does not match.",
  },
};

export const CONFIRM_PASSWORD = {
  label: "Confirm Password",
  validation: {
    required: "Please enter confirm password.",
    match: "Password and Confirm Password does not match.",
  },
};

export const ADDRESS = {
  label: "Address",
  validation: {
    valid: "Please enter an address.",
  },
};

export const CITY = {
  label: "City",
  validation: {
    valid: "Please select a city.",
  },
};

export const STATE = {
  label: "State",
  validation: {
    valid: "Please select a state.",
  },
};

export const ZIP_CODE = {
  label: "Zip code",
  validation: {
    valid: "Please enter a valid zip code.",
    required: "Please enter a zip code.",
  },
};

export const CUSTOMERNAME = {
  label: "Customer name",
  validation: {
    required: "Please enter customer name.",
    valid: "Please enter valid customer name.",
  },
};

export const CONTACT_PERSON = {
  label: "Contact person",
  validation: {
    required: "Please enter contact name.",
  },
};

export const PHONE_EXT = {
  label: "Phone Ext",
  validation: {
    required: "Please enter phone ext.",
  },
};

export const AGENT_NAME = {
  label: "Agent Name",
  validation: {
    required: "Please enter agent name.",
  },
};

export const AGENT_TYPE = {
  label: "Agent Type",
  validation: {
    required: "Please select agent type.",
  },
};

export const HUB_NAME = {
  label: "Hub Name",
  validation: {
    required: "Please enter hub name.",
  },
};

export const ACTIVE_DATE = {
  label: "Active Date",
  validation: {
    required: "Please select active date.",
  },
};

export const DEACTIVATE_DATE = {
  label: "Deactivate Date",
  validation: {
    required: "Please select deactivate date.",
  },
};

export const ORDER_STATUS = {
  label: "Order Status",
  validation: {
    required: "Please select order status type.",
  },
};

export const STATUS_LOCATION = {
  label: "Status Location",
  validation: {
    required: "Please enter status location.",
  },
};

export const STATUS_TIME = {
  label: "Status Time",
  validation: {
    required: "Please select status time.",
  },
};

export const STATUS_DATE = {
  label: "Status Date",
  validation: {
    required: "Please select status date.",
  },
};

export const STATUS_NOTE = {
  label: "Status Note",
  validation: {
    required: "Please enter status note.",
  },
};

export const API_URL = {
  label: "Api Url",
  validation: {
    required: "Please enter xcelerator api url.",
  },
};

export const API_ACCOUNT_NO = {
  label: "Api Account",
  validation: {
    required: "Please enter xcelerator account number.",
  },
};

export const API_LOGIN = {
  label: "Api Login",
  validation: {
    required: "Please enter xcelerator login.",
  },
};

export const API_PASSWORD = {
  label: "Api Password",
  validation: {
    required: "Please enter xcelerator password.",
  },
};

export const START_DATE = {
  label: "Start Date",
  validation: {
    required: "Please select start date.",
  },
};

export const X_SERVICE_CODE = {
  label: "X Service Code",
  validation: {
    required: "Please enter xcelerator service code.",
  },
};

export const X_SERVICE_ID = {
  label: "X Service Id",
  validation: {
    required: "Please enter xcelerator service id.",
  },
};

export const X_SERVICE_DURATION = {
  label: "X Service Duration",
  validation: {
    required: "Please enter xcelerator service duration.",
  },
};

export const X_SERVICE_DETAILS = {
  label: "X Service DETAILS",
  validation: {
    required: "Please enter xcelerator service details.",
  },
};

export const CUSTOMER_TYPE = [
  { name: "Direct Customer", id: "first" },
  { name: "Parent Customer", id: "second" },
  { name: "Sub Customer", id: "third" },
];
export const PACKAGE_TYPE = [
  { id: "BAG", name: "BAG" },
  { id: "BALE", name: "BALE" },
  { id: "BOX", name: "BOX" },
  { id: "BUNDLE", name: "BUNDLE" },
  { id: "CARTON", name: "CARTON" },
  { id: "CASE", name: "CASE" },
  { id: "CRATE", name: "CRATE" },
  { id: "DRUM", name: "DRUM" },
  { id: "PAIL", name: "PAIL" },
  { id: "PLT", name: "PLT" },
  { id: "PIECES", name: "PIECES" },
  { id: "REEL", name: "REEL" },
  { id: "ROLL", name: "ROLL" },
  { id: "SKID", name: "SKID" },
  { id: "TANK", name: "TANK" },
  { id: "TRAILER", name: "TRAILER" },
];

export const EXPEDIET_PACKAGE_TYPE = [
  { id: "A-FRAME", name: "A-FRAME" },
  { id: "ACCESSORIES", name: "ACCESSORIES" },
  { id: "ATTACHMENTS", name: "ATTACHMENTS" },
  { id: "BAGS", name: "BAGS" },
  { id: "BALES", name: "BALES" },
  { id: "BARREL", name: "BARREL" },
  { id: "BASKET", name: "BASKET" },
  { id: "BATTERIES", name: "BATTERIES" },
  { id: "BINS", name: "BINS" },
  { id: "BOX", name: "BOX" },
  { id: "BREAK BULK", name: "BREAK BULK" },
  { id: "BUCKETS", name: "BUCKETS" },
  { id: "BULKHEAD", name: "BULKHEAD" },
  { id: "BUNDLE", name: "BUNDLE" },
  { id: "CANS", name: "CANS" },
  { id: "CARBOYS", name: "CARBOYS" },
  { id: "CART", name: "CART" },
  { id: "CARTON", name: "CARTON" },
  { id: "CASE", name: "CASE" },
  { id: "CHEST", name: "CHEST" },
  { id: "COILS", name: "COILS" },
  { id: "CONTAINER", name: "CONTAINER" },
  { id: "CRATE", name: "CRATE" },
  { id: "CYLINDER", name: "CYLINDER" },
  { id: "DRUM", name: "DRUM" },
  { id: "EACH", name: "EACH" },
  { id: "ENVELOPES", name: "ENVELOPES" },
  { id: "FEET", name: "FEET" },
  { id: "FIRKINS", name: "FIRKINS" },
  { id: "GALLONS", name: "GALLONS" },
  { id: "GAYLORDS", name: "GAYLORDS" },
  { id: "HALFPALLETS", name: "HALFPALLETS" },
  { id: "HOPPER", name: "HOPPER" },
  { id: "JERRICANS", name: "JERRICANS" },
  { id: "KEGS", name: "KEGS" },
  { id: "LIFT VAN", name: "LIFT VAN" },
  { id: "LOOSE", name: "LOOSE" },
  { id: "OCTABIN", name: "OCTABIN" },
  { id: "PACKAGE", name: "PACKAGE" },
  { id: "PAILS", name: "PAILS" },
  { id: "PALLET", name: "PALLET" },
  { id: "PIECE", name: "PIECE" },
  { id: "PLASTIC JERRICANS", name: "PLASTIC JERRICANS" },
  { id: "RACKS", name: "RACKS" },
  { id: "REEL", name: "REEL" },
  { id: "ROLL", name: "ROLL" },
  { id: "SKID", name: "SKID" },
  { id: "SLIPSHEETS", name: "SLIPSHEETS" },
  { id: "STACKS", name: "STACKS" },
  { id: "SUPERSACK", name: "SUPERSACK" },
  { id: "TOTES", name: "TOTES" },
  { id: "TRAYS", name: "TRAYS" },
  { id: "TRUCKLOAD", name: "TRUCKLOAD" },
  { id: "TUBE", name: "TUBE" },
  { id: "TUBS", name: "TUBS" },
  { id: "U-BOX", name: "U-BOX" },
  { id: "UNIT", name: "UNIT" },
  { id: "UNPACKAGED", name: "UNPACKAGED" },
  { id: "VEHICLE", name: "VEHICLE" },
  { id: "WHEELS", name: "WHEELS" },
];

export const FREIGHT_CLASS = [
  { id: "50", name: "50" },
  { id: "55", name: "55" },
  { id: "60", name: "60" },
  { id: "65", name: "65" },
  { id: "70", name: "70" },
  { id: "77.5", name: "77.5" },
  { id: "85", name: "85" },
  { id: "92.5", name: "92.5" },
  { id: "100", name: "100" },
  { id: "110", name: "110" },
  { id: "125", name: "125" },
  { id: "150", name: "150" },
  { id: "175", name: "175" },
  { id: "200", name: "200" },
  { id: "250", name: "250" },
  { id: "300", name: "300" },
  { id: "400", name: "400" },
  { id: "500", name: "500" },
];

export const DATASET = [
  { x: new Date(2024, 11, 1), y: 2 },
  { x: new Date(2024, 11, 5), y: 5.5 },
  { x: new Date(2024, 11, 10), y: 2 },
  { x: new Date(2024, 11, 15), y: 8.5 },
  { x: new Date(2024, 11, 20), y: 1.5 },
  { x: new Date(2024, 11, 25), y: 5 },
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const BOOKING_ORDER_STATUS = [
  "Back Order",
  "Booked",
  "Cancelled",
  "Claim",
  "Pending",
  "Quoted"
];
export const IN_TRANSIT_ORDER_STATUS = [
  "1st Call Made Left Voicemail",
  "Accident",
  "Agent Update Request",
  "Appointment Scheduled",
  "Breakdown",
  "Customer Contacted",
  "Delay not Weather",
  "Delay, Weather",
  "Dispatched",
  "Estimated Delivery Date",
  "Expected Receive Date to Agent",
  "Holiday",
  "In Transit",
  "In Transit from DC to Final Mile Hub",
  "Incorrect Address",
  "Left Voicemail",
  "Loaded on Truck",
  "Mis-sort",
  "Missed Pick-up",
  "Need contact info",  
  "Not Picked Up",
  "On Hold",
  "Picked up",
  "Pickup Scheduled",
  "Ready to Route",
  "Received",
  "Received at Distribution Center",
  "Received Damaged",
  "Reconsignment",
  "Request of LTL PRO",
  "Requested Update from Customer",
  "Rescheduled",
  "Return ready",
  "Weather",
  "Open"
];
export const OUT_DELIVERY_ORDER_STATUS = [
  "Delivery Attempted",
  "Out for delivery",
  "Refused/Attempted",
];
export const DELIVERED_ORDER_STATUS = [
  "Delivered",
  "Delivered Awaiting POD",
  "Delivered Damaged",
  "Delivered Over",
  "Delivered Short",
  "Finalized",
  "Invoiced",
  "POD Requested from Agent",
];
export const CANCELLED_ORDER_STATUS = ["Cancelled"];
